.blue-map-icon {
  content: url('../assets/icons/blue_map.svg');
}

.blue-cluster-icon {
  content: url('../assets/icons/blue_cluster.svg');
}

.blue-rover-icon {
  content: url('../assets/icons/blue_rover.svg');
}

.blue-group-icon {
  content: url('../assets/icons/blue_group.svg');
}

.blue-activity-icon {
  content: url('../assets/icons/blue_activity.svg');
}

.blue-logs-icon {
  content: url('../assets/icons/blue_logs.svg');
}

.blue-maintenance-icon {
  content: url('../assets/icons/blue_maintenance.svg');
}

.blue-help-icon {
  content: url('../assets/icons/blue_help.svg');
}

.blue-itinerary {
  content: url('../assets/icons/blue_itinerary_icon.svg');
}

.blue-add {
  content: url('../assets/icons/blue_plus_icon.svg');
}

.blue-envelope {
  content: url('../assets/icons/blue_envelope.svg');
}

.blue-grass {
  content: url('../assets/icons/blue_grass.svg');
}

.grey-grass {
  content: url('../assets/icons/grey_grass.svg');
}

.blue-target-zone {
  content: url('../assets/icons/blue_target_zone.svg');
}

.blue-reduce {
  content: url('../assets/icons/blue_reduce.svg');
}

.blue-hdz {
  content: url('../assets/icons/blue_hdz.svg');
}

.blue-add-rover {
  content: url('../assets/icons/blue_add_rover.svg');
}

.blue-add-user {
  content: url('../assets/icons/blue_add_user.svg');
}

.blue-extend {
  content: url('../assets/icons/blue_extend.svg');
}

.blue-tools-work-duration {
  content: url('../assets/icons/blue_tools_work_duration.svg');
}

.blue-travel-time {
  content: url('../assets/icons/blue_travel_time.svg');
}

.blue-traveled-distance {
  content: url('../assets/icons/blue_traveled_distance.svg');
}

.blue-work-duration {
  content: url('../assets/icons/blue_work_duration.svg');
}

.notification-with-badge {
  content: url('../assets/icons/notification_with_badge.svg');
}

.notification-without-badge {
  content: url('../assets/icons/notification_without_badge.svg');
}

.battery {
  content: url('../assets/icons/battery_icon.svg');
}

.status-icon {
  content: url('../assets/icons/status_icon.svg');
}

.back {
  content: url('../assets/icons/back_icon.svg');
  border: 1px solid;
  height: 1em;
  width: 1em;
  padding: 0.5em;
  border-radius: 50%;
  cursor: pointer;
}

.white-back {
  content: url('../assets/icons/white_back_icon.svg');
  border: 1px solid;
  height: 1em;
  width: 1em;
  padding: 0.5em;
  border-radius: 50%;
  cursor: pointer
}

.chevron {
  content: url('../assets/icons/back_icon.svg');
}

.pencil {
  content: url('../assets/icons/pencil-icon.svg');
}

.operating_status_icon {
  content: url('../assets/icons/operating_status_icon.svg');
}

.alert {
  content: url('../assets/icons/alert_icon.svg');
}

.search {
  content: url('../assets/icons/search_icon.svg');
}

.earth {
  content: url('../assets/icons/earth_icon.svg');
}

.valid {
  content: url('../assets/icons/valid_icon.svg');
  //width: 6px;
  //height: 6px;
  background-color: #2699FB;
  border: 1px solid #2699FB;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  padding: 3px;
  display: block;
}
